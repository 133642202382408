import React, { useEffect, useState } from "react";
import {
  leftArrowImg,
  profileRingIcon,
  certificationsIcon,
  defaultBrandImage,
  heyhireNewLogoIcon,
  heyhireNewBlackLogoIcon,
} from "../../assets/images";
import { useNavigate, useParams, useLocation } from "react-router-dom";
import { Collapse, Space } from "antd";
import { getPlatform } from "../../utils";
import { Tooltip } from "react-tooltip";
import Location from "../../api/Location";
import "../../utils/list-styles.css";

const { Panel } = Collapse;

const PositionProfile = () => {
  const [platform, setPlatform] = useState("ios");
  const location = useLocation();
  const params = useParams();
  const storedLocation = JSON.parse(localStorage.getItem("currentLocation"));
  const [certificatesForLocation, setCertificatesForLocation] = useState([]);
  const navigate = useNavigate();
  const [distance, setDistance] = useState(null);
  const [positionDetail, setPositionDetail] = useState(null);
  const [brandDetail, setBrandDetail] = useState(null);
  const [addressDetail, setAddressDetail] = useState(null);
  const groupName = location.state && location.state.groupName;

  const position = location.state && location.state.position;
  const brandInfo = location.state && location.state.location;
  const isMultipleLocation = location.state && location.state.multipleLocation;
  const navigatePosition = location.state && location.state.navigatePosition;
  const ogImageUrl =
    brandInfo?.brand?.photo?.thumb_url ||
    (position?.photo?.photo
      ? position?.photo?.photo?.thumb_url
      : defaultBrandImage);

  function getCertificatesByLocationId(certificates, positionId) {
    const uniqueCertificates = new Set();

    certificates?.forEach((certificate) => {
      certificate?.job_positions?.forEach((item) => {
        if (item.id === positionId) {
          if (certificate.name && certificate.name.trim() !== "") {
            uniqueCertificates.add(certificate.name);
          }
        }
      });
    });

    return Array.from(uniqueCertificates);
  }

  useEffect(() => {
    const _platform = getPlatform();
    setPlatform(_platform);
    if (brandInfo && position) {
      document.title = `HeyHire - ${position?.title} At ${brandInfo?.name} In ${brandInfo?.address?.city}`;
      const result = getCertificatesByLocationId(
        brandInfo.certificates,
        position.id
      );
      setCertificatesForLocation(result);
    }
    if (params?.businessName && params?.locationName && params?.positionName) {
      Location.getBySlug(`${params.businessName}/${params.locationName}`)
        .then((response) => {
          document.title = `HeyHire - ${params?.positionName} At ${response?.data?.data?.brand?.name} In ${response?.data?.data?.address?.city}`;
          const _positions = response.data.data.positions;
          const filterJobs = _positions.filter((position) => {
            return (
              position.archived_at === null &&
              position.title === params.positionName
            );
          });

          const result = getCertificatesByLocationId(
            response?.data?.data?.certificates,
            filterJobs[0].id
          );
          setCertificatesForLocation(result);
          setPositionDetail(filterJobs[0]);
          setBrandDetail(response?.data?.data?.brand);
          setAddressDetail(response?.data?.data?.address);
        })
        .catch((error) => {
          console.log(error);
        });
    }
  }, [brandInfo, position]);

  useEffect(() => {
    if (storedLocation && storedLocation?.city && storedLocation?.state) {
      if (brandInfo) {
        handleCalculateDistance(
          storedLocation?.latitude,
          storedLocation?.longitude,
          brandInfo?.address?.lat,
          brandInfo?.address?.lng
        );
      } else if (addressDetail) {
        handleCalculateDistance(
          storedLocation?.latitude,
          storedLocation?.longitude,
          addressDetail?.lat,
          addressDetail?.lng
        );
      }
    }
  }, [storedLocation]);

  const handleCalculateDistance = (lat1, long1, lat2, long2) => {
    const R = 3958.8; // Earth radius in miles

    const numLat1 = parseFloat(lat1);
    const numLong1 = parseFloat(long1);
    const numLat2 = parseFloat(lat2);
    const numLong2 = parseFloat(long2);

    if (
      isNaN(numLat1) ||
      isNaN(numLong1) ||
      isNaN(numLat2) ||
      isNaN(numLong2)
    ) {
      console.error("Invalid coordinates provided");
      return;
    }

    const dLat = toRadians(numLat2 - numLat1);
    const dLon = toRadians(numLong2 - numLong1);

    const a =
      Math.sin(dLat / 2) * Math.sin(dLat / 2) +
      Math.cos(toRadians(numLat1)) *
        Math.cos(toRadians(numLat2)) *
        Math.sin(dLon / 2) *
        Math.sin(dLon / 2);

    const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));

    const distance = R * c;
    setDistance(distance.toFixed(2));
  };

  const toRadians = (degrees) => {
    return degrees * (Math.PI / 180);
  };

  const openStore = () => {
    var isiOS =
        navigator.userAgent.match("iPad") ||
        navigator.userAgent.match("iPhone") ||
        navigator.userAgent.match("iPod"),
      isAndroid = navigator.userAgent.match("Android");
    if (isAndroid) {
      window.open(
        "https://play.google.com/store/apps/details?id=com.heyhire&pli=1"
      );
    } else {
      window.open("https://apps.apple.com/us/app/heyhire/id1628579684");
    }
  };

  const openpage = () => {
    var isiOS =
        navigator.userAgent.match("iPad") ||
        navigator.userAgent.match("iPhone") ||
        navigator.userAgent.match("iPod"),
      isAndroid = navigator.userAgent.match("Android");

    // Mobile
    if (isiOS || isAndroid) {
      if (isiOS) {
        setTimeout(function () {
          window.location =
            "https://apps.apple.com/us/app/heyhire/id1628579684";
        }, 50);
        if (position) window.location = `heyhire.app://?job_id=${position?.id}`;
        if (positionDetail)
          window.location = `heyhire.app://?job_id=${positionDetail?.id}`;
      } else if (isAndroid) {
        setTimeout(function () {
          window.location =
            "https://play.google.com/store/apps/details?id=com.heyhire&pli=1";
        }, 100);
        if (position) window.location = `heyhire.app://?job_id=${position?.id}`;
        if (positionDetail)
          window.location = `heyhire.app://?job_id=${positionDetail?.id}`;
      }
    }
  };

  return (
    <div className="h-screen flex flex-col">
      <div className="w-full fixed z-10 bg-white flex-shrink-0">
        <div className="h-16 md:h-20 top-0 w-full bg-gray-900 flex md:rounded-b-2xl z-[1]">
          <div className="m-4 px-4 md:px-0 mx-auto my-auto w-full">
            <div className="flex justify-between w-full md:w-[75%] xl:w-[80%] ml-auto md:px-6 xl:px-24 2xl:px-36">
              <div className="flex flex-col justify-center md:items-center cursor-pointer">
                <img
                  className="w-28 md:w-36"
                  src={heyhireNewLogoIcon}
                  alt="heyhire"
                  onClick={() => {
                    navigate("/jobs");
                  }}
                />
                <span className="font-bold text-[8px] w-28 md:w-36 flex justify-end text-white">
                  {storedLocation?.city}, {storedLocation?.state}
                </span>
              </div>

              <div className="my-auto">
                <div
                  className="cursor-pointer px-6 flex items-center justify-center bg-white text-black hover:bg-gray-900 hover:text-white hover:border-white border transition-all text-center font-bold whitespace-nowrap text-ellipsis rounded-full py-2"
                  onClick={() =>
                    window.open("https://www.heyhire.com/", "_blank")
                  }
                >
                  Post a Job
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="pt-20 md:flex hidden h-full">
        <div className="w-[25%] xl:w-[20%] bg-white p-4 border-r border-warmgray-300 flex flex-col rounded-br-2xl">
          <div className="relative flex justify-center bg-white rounded-full p-1 w-36 h-36 mx-auto my-4">
            <img
              className="rounded-full object-contain"
              height="125"
              width="125"
              src={
                brandDetail?.photo
                  ? brandDetail?.photo?.thumb_url
                  : brandInfo?.brand?.photo
                  ? brandInfo?.brand?.photo?.thumb_url
                  : position?.photo?.photo
                  ? position?.photo?.photo?.thumb_url
                  : defaultBrandImage
              }
            />
            <img
              src={profileRingIcon}
              alt="avatar"
              className="absolute inset-0"
            />
          </div>
          <div className="text-center">
            <p className="font-extrabold text-[20px]">
              {brandInfo?.name || brandDetail?.name}
            </p>
            <div className="flex flex-col my-6 gap-1.5">
              {(brandInfo?.address?.address || addressDetail) && (
                <p className="font-arial font-semibold text-[15px]">
                  <span>
                    <i className="fas fa-map-marker-alt text-black text-[15px] my-auto" />
                  </span>{" "}
                  {brandInfo?.address?.address || addressDetail?.address},{" "}
                  {brandInfo?.address?.city || addressDetail?.city},{" "}
                  {brandInfo?.address?.state || addressDetail?.state},{" "}
                  {brandInfo?.address?.zip || addressDetail?.zip}
                </p>
              )}
              <p className="font-arial font-semibold text-[15px]">
                {distance && `${distance} mi away`}
              </p>
            </div>
            <hr className="w-full border border-warmgray-200" />
          </div>
          <div className="mx-auto text-center">
            <p className="font-extrabold mt-4 mb-2">
              <span className="font-normal text-sm">Open Position: </span>
              {position
                ? position?.title
                : positionDetail
                ? positionDetail?.title
                : null}
            </p>
            <div className="text-[14px] font-bold text-primary-500 flex items-center justify-center">
              <span className="text-[14px] font-bold text-primary-500 my-auto">
                {position
                  ? position?.hiring_status === 1
                    ? "Immediately Hiring"
                    : position?.hiring_status === 2
                    ? "Externally Hiring"
                    : "Accepting Applications"
                  : positionDetail
                  ? positionDetail?.hiring_status === 1
                    ? "Immediately Hiring"
                    : positionDetail?.hiring_status === 2
                    ? "Externally Hiring"
                    : "Accepting Applications"
                  : null}
              </span>
              <span>
                <i
                  className="fas fa-info-circle text-black text-lg ml-2 cursor-pointer"
                  data-tooltip-id="hiring_info"
                  data-tooltip-content={
                    position
                      ? position?.hiring_status === 1
                        ? "This position needs to be filled ASAP. Apply now and expect to hear back shortly!"
                        : position?.hiring_status === 2
                        ? `${
                            brandInfo?.name || brandDetail?.name
                          } is hiring on their website. You may apply here and we'll send them your application!`
                        : "This business isn't immediately hiring for this position. They may take some time reaching out to you."
                      : positionDetail
                      ? positionDetail?.hiring_status === 1
                        ? "This position needs to be filled ASAP. Apply now and expect to hear back shortly!"
                        : positionDetail?.hiring_status === 2
                        ? `This business is hiring outside of HeyHire. If you apply here, we will send them your application for you!`
                        : "This business isn't immediately hiring for this position. They may take some time reaching out to you."
                      : null
                  }
                />
              </span>
            </div>
          </div>
          <div className="mt-auto mb-4">
            <div className="flex justify-center mb-6">
              <button
                onClick={() =>
                  window.open(
                    "https://play.google.com/store/apps/details?id=com.heyhire&pli=1"
                  )
                }
              >
                <img
                  width={130}
                  src="/images/play_store_badge.png"
                  alt="Play Store"
                />
              </button>
              <button
                onClick={() =>
                  window.open(
                    "https://apps.apple.com/us/app/heyhire/id1628579684"
                  )
                }
              >
                <img
                  width={120}
                  className="ml-2"
                  src="/images/app_store_badge.png"
                  alt="App Store"
                />
              </button>
            </div>
            <div className="py-4 flex justify-center">
              <img
                height={180}
                width={180}
                alt="logo"
                src={heyhireNewBlackLogoIcon}
              />
            </div>
          </div>
        </div>

        <div className="w-[75%] xl:w-[80%] bg-white overflow-y-auto md:px-6 xl:px-24 2xl:px-36">
          <div className="bg-white rounded-br-3xl py-6 xl:py-10 text-center border border-warmgray-200 shadow-lg">
            <p className="font-extrabold text-xl md:text-2xl xl:text-3xl">
              <span className="text-gray-900">Join our team! </span>
              <span className="text-[#BC74A9]">Apply in just a few taps!</span>
            </p>
          </div>

          <div className="my-6">
            <div
              className="flex cursor-pointer"
              onClick={() =>
                groupName
                  ? navigate(`/group/${groupName}`)
                  : navigatePosition || positionDetail
                  ? navigate("/jobs")
                  : isMultipleLocation
                  ? navigate(`/${params.businessName}`)
                  : navigate(`/${params.businessName}/${params.locationName}`)
              }
            >
              <img src={leftArrowImg} className="w-4 h-4 my-auto mr-2" />
              <span className="text-[15px] font-normal">
                {groupName
                  ? "Groups"
                  : navigatePosition || positionDetail
                  ? "Positions"
                  : brandInfo?.name}
              </span>
            </div>

            <div className="bg-white px-12 pt-8 pb-4 mt-8 rounded-xl border border-warmgray-200 shadow-lg">
              <div className="flex flex-col gap-1">
                <p className="font-extrabold text-lg">Position Description</p>
                {(position?.description || positionDetail?.description) && (
                  <div
                    className="text-[14px] leading-6"
                    dangerouslySetInnerHTML={{
                      __html:
                        position?.description ||
                        positionDetail?.description ||
                        "",
                    }}
                  />
                )}
              </div>

              <div className="flex flex-col gap-1 my-8">
                <p className="font-extrabold text-lg">Required Experience</p>
                {(position?.experience || positionDetail?.experience) && (
                  <div
                    className="text-[14px] leading-6"
                    dangerouslySetInnerHTML={{
                      __html:
                        position?.experience ||
                        positionDetail?.experience ||
                        "",
                    }}
                  />
                )}
              </div>

              <div className="flex gap-3 mb-8">
                <div className="w-1/2">
                  <p className="font-extrabold text-lg">
                    Required Certifications
                  </p>
                  <div className="flex flex-wrap gap-1 mt-2">
                    {certificatesForLocation &&
                    certificatesForLocation.length > 0 ? (
                      certificatesForLocation.map((item) => (
                        <div
                          key={item}
                          className="p-2 rounded-md min-w-24 max-w-40 flex flex-col"
                        >
                          <img
                            src={certificationsIcon}
                            alt="logo"
                            className="w-10 h-10 mx-auto"
                          />
                          <p className="mt-1 font-bold text-center text-sm">
                            {item}
                          </p>
                        </div>
                      ))
                    ) : (
                      <div className="rounded-md min-w-24 max-w-40 flex flex-col">
                        <p className="text-sm">Unspecific</p>
                      </div>
                    )}
                  </div>
                </div>

                <div className="w-1/2">
                  <p className="font-extrabold text-lg">Compensation</p>
                  {(position && position.wage && position.wage.trim() !== "") ||
                  (positionDetail &&
                    positionDetail.wage &&
                    positionDetail.wage.trim() !== "") ? (
                    <p className="mt-2 text-sm font-bold text-arial">
                      {position?.wage || positionDetail?.wage}
                    </p>
                  ) : (
                    <p className="mt-2 text-sm font-bold text-arial">
                      {position?.wage || positionDetail?.wage}
                    </p>
                  )}
                </div>
              </div>

              <div className="flex justify-center mb-1">
                <div
                  className="cursor-pointer border w-1/5 font-extrabold py-2 px-3 rounded-full bg-gray-900 text-white hover:bg-white hover:text-black hover:border-black text-center"
                  onClick={() => openStore()}
                >
                  Apply Now
                </div>
              </div>
            </div>
          </div>
        </div>

        <Tooltip id="hiring_info" style={{ width: "300px" }} />
      </div>

      <div className="h-screen pt-16 flex md:hidden flex-col bg-white">
        <div className="bg-white">
          <div className="py-6 text-center border border-warmgray-200 rounded-b-2xl shadow-md">
            <p>
              <span className="text-lg text-gray-900 font-extrabold">
                Join our team!{" "}
              </span>
              <span className="text-lg text-[#BC74A9] font-extrabold">
                Apply in just a few taps!
              </span>
            </p>
          </div>

          <div
            className="flex cursor-pointer px-4 mt-2"
            onClick={() =>
              groupName
                ? navigate(`/group/${groupName}`)
                : navigatePosition || positionDetail
                ? navigate("/jobs")
                : isMultipleLocation
                ? navigate(`/${params.businessName}`)
                : navigate(`/${params.businessName}/${params.locationName}`)
            }
          >
            <img src={leftArrowImg} className="w-3 h-3 my-auto mr-2" />
            <span className="text-[12px] font-normal">
              {groupName
                ? "Groups"
                : navigatePosition || positionDetail
                ? "Positions"
                : brandInfo?.name}
            </span>
          </div>

          <div className="z-0 relative flex justify-center bg-white rounded-full p-1 w-32 h-32 mx-auto my-4">
            <img
              className="rounded-full object-contain"
              height="100"
              width="100"
              src={
                brandDetail?.photo
                  ? brandDetail?.photo?.thumb_url
                  : defaultBrandImage
              }
            />
            <img
              src={profileRingIcon}
              alt="avatar"
              className="absolute inset-0"
            />
          </div>

          <div className="text-center">
            <p className="font-extrabold text-[16px] px-4 ">
              {brandInfo?.name || brandDetail?.name}
            </p>
            <div className="flex flex-col mb-4 mt-2 px-4">
              {(brandInfo?.address?.address || addressDetail) && (
                <p className="font-arial font-bold text-[10px]">
                  <span>
                    <i className="fas fa-map-marker-alt text-black text-[10px] my-auto" />
                  </span>{" "}
                  {brandInfo?.address?.address || addressDetail?.address},{" "}
                  {brandInfo?.address?.city || addressDetail?.city},{" "}
                  {brandInfo?.address?.state || addressDetail?.state},{" "}
                  {brandInfo?.address?.zip || addressDetail?.zip}
                </p>
              )}
              <p className="font-arial font-bold text-[10px]">
                {distance && `${distance} mi away`}
              </p>
            </div>
            <hr className="w-full border-[0.5px] border-warmgray-200" />
          </div>
          <div className="mx-auto text-center my-4">
            <p className="font-extrabold">
              {position
                ? position?.title
                : positionDetail
                ? positionDetail?.title
                : null}
            </p>
            <div className="text-[12px] font-bold text-primary-500 flex justify-center mt-2">
              <span className="text-[12px] font-bold text-primary-500 my-auto">
                {position
                  ? position?.hiring_status === 1
                    ? "Immediately Hiring"
                    : position?.hiring_status === 2
                    ? "Externally Hiring"
                    : "Accepting Applications"
                  : positionDetail
                  ? positionDetail?.hiring_status === 1
                    ? "Immediately Hiring"
                    : positionDetail?.hiring_status === 2
                    ? "Externally Hiring"
                    : "Accepting Applications"
                  : null}
              </span>
              <span>
                <i
                  className="fas fa-info-circle text-black text-sm ml-2 cursor-pointer"
                  data-tooltip-id="hiring_info"
                  data-tooltip-content={
                    position
                      ? position?.hiring_status === 1
                        ? "This position needs to be filled ASAP. Apply now and expect to hear back shortly!"
                        : position?.hiring_status === 2
                        ? `${
                            brandInfo?.name || brandDetail?.name
                          } is hiring on their website. You may apply here and we'll send them your application!`
                        : "This business isn't immediately hiring for this position. They may take some time reaching out to you."
                      : positionDetail
                      ? positionDetail?.hiring_status === 1
                        ? "This position needs to be filled ASAP. Apply now and expect to hear back shortly!"
                        : positionDetail?.hiring_status === 2
                        ? `This business is hiring outside of HeyHire. If you apply here, we will send them your application for you!`
                        : "This business isn't immediately hiring for this position. They may take some time reaching out to you."
                      : null
                  }
                />
              </span>
            </div>
          </div>
        </div>

        <div className="border border-warmgray-200 mx-4 mt-4 mb-8 rounded-xl shadow-lg">
          <div className="bg-white p-6 rounded-xl">
            <div className="flex flex-col gap-2 mt-4">
              <p className="font-extrabold text-[14px]">Position Description</p>
              {(position?.description || positionDetail?.description) && (
                <div
                  className="font-bold text-[12px] leading-6 prose"
                  dangerouslySetInnerHTML={{
                    __html:
                      position?.description ||
                      positionDetail?.description ||
                      "",
                  }}
                />
              )}
            </div>

            <div className="flex flex-col gap-1 my-8">
              <p className="font-extrabold text-[14px]">Required Experience</p>
              {(position?.experience || positionDetail?.experience) && (
                <div
                  className="text-[12px] leading-6"
                  dangerouslySetInnerHTML={{
                    __html:
                      position?.experience || positionDetail?.experience || "",
                  }}
                />
              )}
            </div>

            <div className="flex flex-col">
              <div className="mb-8">
                <p className="font-extrabold text-[14px]">
                  Required Certifications
                </p>
                <div className="flex flex-wrap gap-1 mt-2">
                  {certificatesForLocation &&
                  certificatesForLocation.length > 0 ? (
                    certificatesForLocation?.map((item) => (
                      <div className="p-2 rounded-md min-w-24 max-w-40 flex flex-col">
                        <img
                          src={certificationsIcon}
                          alt="logo"
                          className="w-6 h-6 mx-auto"
                        />
                        <p className="mt-1 font-bold text-center text-[12px]">
                          {item}
                        </p>
                      </div>
                    ))
                  ) : (
                    <div className="rounded-md min-w-24 max-w-40 flex flex-col">
                      <p className="mt-1 text-sm">Unspecific</p>
                    </div>
                  )}
                </div>
              </div>

              <div className="mb-8">
                <p className="font-extrabold text-[14px]">Compensation</p>
                {(position && position.wage && position.wage.trim() !== "") ||
                (positionDetail &&
                  positionDetail.wage &&
                  positionDetail.wage.trim() !== "") ? (
                  <p className="font-bold text-[12px] leading-6">
                    {position?.wage || positionDetail?.wage}
                  </p>
                ) : (
                  <p className="mt-1 text-sm">Unspecific</p>
                )}
              </div>
            </div>

            <div className="flex justify-center mb-1">
              <div
                className="cursor-pointer w-full font-extrabold py-2 px-3 rounded-full bg-gray-900 text-white hover:bg-white border hover:border-black hover:text-black text-center"
                onClick={() => {
                  openpage();
                }}
              >
                Apply Now
              </div>
            </div>
          </div>
        </div>

        <div className="bg-white justify-center text-center p-2 mt-auto flex border-t border-warmgray-200">
          <p className="my-auto mr-2 text-sm">
            Want to <span className="font-bold">apply</span> to this{" "}
            <span className="font-bold">business</span>?
          </p>

          <button
            onClick={() => {
              if (platform == "ios") {
                window.open(
                  "https://apps.apple.com/us/app/heyhire/id1628579684"
                );
              } else {
                window.open(
                  "https://play.google.com/store/apps/details?id=com.heyhire&pli=1"
                );
              }
            }}
          >
            <img
              width={80}
              className="ml-4"
              src={
                platform === "ios"
                  ? "/images/app_store_badge.png"
                  : "/images/play_store_badge.png"
              }
              alt="App Store"
            />
          </button>
        </div>

        <Tooltip id="hiring_info" style={{ width: "200px" }} />
      </div>
    </div>
  );
};

export default PositionProfile;
